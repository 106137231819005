import Vue from 'vue'

const logger = console
const PRESS_TIMEOUT = 1000

let pressTimer = null

const longpress = {
  events: {
    cancel: ['click', 'mouseout', 'touchend', 'touchcancel'],
    start: ['mousedown', 'touchstart'],
  },
  cancel() {
    return () => {
      if (pressTimer !== null) {
        clearTimeout(pressTimer)
        pressTimer = null
      }
    }
  },
  start(value) {
    return (e) => {
      if (e.type === 'click' && e.button !== 0) {
        return
      }

      if (pressTimer === null) {
        pressTimer = setTimeout(() => value(e), PRESS_TIMEOUT)
      }
    }
  },
  set(el, binding /* , vNode */) {
    const { value } = binding
    const { cancel, events, start } = this

    const setCancelEvent = (e) => el.addEventListener(e, cancel(value))
    const setStartEvent = (e) => el.addEventListener(e, start(value))

    events.cancel.forEach(setCancelEvent)
    events.start.forEach(setStartEvent)

    return this
  },
}

Vue.directive('longpress', {
  bind(el, binding /* , vNode */) {
    const { value } = binding

    if (typeof value !== 'function') {
      logger.warn(`Expected a function, not a(n) ${value}`)
      return value
    }

    return longpress.set(el, binding)
  },
})

export default longpress
