import Vue from 'vue'
import IdleVue from 'idle-vue'

import store from '../store/store'

const eventsHub = new Vue()
const options = {
  eventEmitter: eventsHub,
  idleTime: 10000,
  store,
}

Vue.use(IdleVue, options)

export default IdleVue
