import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import hydrogen from '../styles/theme/hydrogen'

const { themes } = hydrogen

const options = {
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: { themes },
}

Vue.use(Vuetify)

export default new Vuetify(options)
