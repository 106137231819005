import _ from 'underscore'

// Note: the International Collator does _NOT_ support android web view:
// ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Collator#Browser_compatibility
const collator = new Intl.Collator('en', {
  numeric: true,
  sensitivity: 'base',
  ignorePunctuation: false,
})

function asc(left, right) {
  if (typeof left === 'string' || typeof right === 'string') {
    return collator.compare(left, right)
  }

  return left - right
}

function dsc(left, right) {
  if (typeof left === 'string' || typeof right === 'string') {
    return collator.compare(right, left)
  }

  return right - left
}

const getValues = (left, right, propertyOrFn, ctx) => {
  let leftValue = left
  let rightValue = right

  if (propertyOrFn && typeof propertyOrFn === 'string') {
    leftValue = left[propertyOrFn]
    rightValue = right[propertyOrFn]
  } else if (propertyOrFn && typeof propertyOrFn === 'function') {
    leftValue = propertyOrFn.call(ctx, left)
    rightValue = propertyOrFn.call(ctx, right)
  }

  return {
    left: leftValue,
    right: rightValue,
  }
}

_.mixin({
  sortAscByCaseInsensitive(list, propertyOrFn, context) {
    const ctx = context || this
    const listCopy = [...list]

    return listCopy.sort((leftVal, rightVal) => {
      const values = getValues(leftVal, rightVal, propertyOrFn, ctx)
      const { left, right } = values

      return asc(left, right)
    })
  },
  sortDscByCaseInsensitive(list, propertyOrFn, context) {
    const ctx = context || this
    const listCopy = [...list]

    return listCopy.sort((leftVal, rightVal) => {
      const values = getValues(leftVal, rightVal, propertyOrFn, ctx)
      const { left, right } = values

      return dsc(left, right)
    })
  },
})
