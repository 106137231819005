import Vue from 'vue'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import hydrogen from '../styles/theme/hydrogen'

const { VUE_APP_SENTRY_DSN } = process.env
const { colors } = hydrogen
// const lb = '\n\n '

const logger = {
  styles: {
    error: [
      `background: #${colors.red.hex}, color: #${colors.white.hex}, font-weight: bold`,
      `color: #${colors.coolgray.hex}`,
    ],
    info: [
      `background: #${colors.brightblue.hex}, color: #${colors.white.hex}; font-weight: bold`,
      `color: #${colors.coolgray.hex}`,
    ],
    log: [
      `background: #${colors.coolgray.hex}, color: #${colors.white.hex}, font-weight: bold`,
      `color: #${colors.coolgray.hex}`,
    ],
    warn: [
      `background: #${colors.toasted.hex}, color: #${colors.white.hex}, font-weight: bold`,
      `color: #${colors.coolgray.hex}`,
    ],
  },
  sentry: {
    config(vueInstance, router) {
      return {
        Vue: vueInstance,
        dsn: VUE_APP_SENTRY_DSN,
        environment: process.env.NODE_ENV,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ['localhost:8080', 'beta.iadops.com', 'iadops.com', 'uat.iadops.com', /^\//],
          }),
        ],
        logErrors: true,
        release: `iadops-ui@${process.env.VUE_APP_VERSION || process.env.npm_package_version}`,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      }
    },
    install(vueInstance, router) {
      Sentry.init(this.config(vueInstance, router))
    },
    clear() {
      Sentry.configureScope((scope) => scope.clear())
    },
    setUser(user) {
      Sentry.setUser(user)
    },
    setTags(tags) {
      Sentry.setTags(tags)
    },
    captureException(exception, captureContext) {
      return Sentry.captureException(exception, captureContext)
    },
  },
  console: {
    set(level, text, options) {
      const consoleLogger = console
      const isSimpleString = !/%c/.test(text)

      if (isSimpleString) {
        consoleLogger[level](text)
      } else {
        const arrOptions = Array.isArray(options) ? options : [options]
        consoleLogger[level](text, ...arrOptions)
      }
    },
    error(text, options) {
      const level = 'error'
      logger.console.set(level, text, options || logger.styles[level])
    },
    info(text, options) {
      const level = 'info'
      logger.console.set(level, text, options || logger.styles[level])
    },
    log(text, options) {
      const level = 'log'
      logger.console.set(level, text, options || logger.styles[level])
    },
    warn(text, options) {
      const level = 'warn'
      logger.console.set(level, text, options || logger.styles[level])
    },
  },
  handle: {
    errorHandler(error, vm, info) {
      Sentry.captureException(error, {
        tags: {
          info,
        },
      })
    },
    // @Note The warnHandler is disabled in production mode, and it's redundant to errorHandler, so it's not in use.
    // warnHandler(msg, vm, trace) {
    //   const options = logger.styles.warn.concat(logger.styles.info)
    //   logger.console.warn(`%cWarn%c ${msg}\n\n %cTrace%c ${trace}`, options)
    // },
    errorCaptured(error, vm, info) {
      Sentry.captureException(error, {
        tags: {
          info,
        },
      })
    },
    windowOnError(msg, src, linenum, colnum, error) {
      Sentry.captureException(error, {
        tags: { msg, src, linenum, colnum },
      })
    },
    env(appName, appVersion, isProd) {
      const buildEnv = isProd ? 'Production' : 'Development'
      logger.console.info(`%c${appName}%c\n${appVersion}\n${buildEnv} Build`)
    },
  },
  vue: {
    config(options) {
      return {
        error(text) {
          logger.console.error(text, options)
        },
        info(text) {
          logger.console.info(text, options)
        },
        log(text) {
          logger.console.log(text, options)
        },
        warn(text) {
          logger.console.warn(text, options)
        },
      }
    },
    install(vueInstance, options) {
      vueInstance.prototype.$log = logger.vue.config(options)
    },
  },
}

const { install } = logger.vue
const Logger = { install }

Vue.use(Logger)

window.onerror = logger.handle.windowOnError

Vue.config.errorHandler = logger.handle.errorHandler
// @Note The warnHandler is disabled in production mode, and it's redundant to errorHandler, so it's not in use.
// Vue.config.warnHandler = logger.handle.warnHandler

export default logger
